import { OIP } from "js-oip";
import React from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// import { display } from "@material-ui/system";

import {
  Box,
  TextField,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";

import { OipApi } from "oip/OipApi";
import { config } from "ddx.config.js";

// "tmpl_636E68FA"

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  avatar: {
    maxWidth: "125px",
    border: "solid"
  },
  thumb: {
    maxWidth: "75px",
    border: "solid"
  },
  address: {
    fontSize: "0.1rem"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  }
});

class Sample extends React.Component {
  state = {
    update: true,
    sampleType: "",
    environmentType: "",
    location: "",
    byList: [],
    organizationList: [],
    preparedFromList: [],
    protocolList: [],
    legacyIdList: [],
    byListName: [],
    byListOptions: [],
    preparedFromListName: [],
    protocolListName: [],
    organizationListName: [],

  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value }, this.updateParent());
  };

  updateParent = () => {
    this.setState({ update: !this.state.update }, () => {
      const payload = {
        update: this.state.update,
        sampleType: this.state.sampleType,
        environmentType: this.state.environmentType,
        location: this.state.location,
        byList: this.state.byList,
        organizationList: this.state.organizationList,
        preparedFromList: this.state.preparedFromList,
        protocolList: this.state.protocolList,
        legacyIdList: this.state.legacyIdList,
        byListName: this.state.byListName
      };
      this.props.onChangePayload(payload);
    });
  };

  handleChangeList = (name, checkOip) => event => {
    const input = event.target.value.split("\n");
    while (input.indexOf("") !== -1) {
      const rm = input.indexOf("");
      input.splice(rm, 1);
    }
    console.log(`handler: ${input}`);
    this.setState(
      { [name]: input },
      () => checkOip && this.setNames(name, checkOip)
    );
  };

  setNames = async name => {
    console.log("setNames");
    const names = [];
    const ids = this.state[name];
    console.log(ids);
    for (let id of ids) {
      const name = await this.oip2name(id);
      if (name !== "") {
        names.push(name);
      }
    }
    this.setState({ [`${name}Name`]: names }, () => this.updateParent());
  };

  oip2name = id => {
    const oip = new OipApi();
    console.log(id);
    return oip.getRecord(id).then(record => {
      console.log(record);
      if (record.results.length === 1 && record.results[0].meta.txid === id) {
        const name =
          record.results[0].record.details[config.cardInfo.name.tmpl][
            config.cardInfo.name.name
          ];
        console.log(`record: ${JSON.stringify(name)}`);
        return name;
      }
      console.log(`no record found!`);
      return "";
    });
  };

  renderValue = (component, label) => {
    const { classes } = this.props;
    return (
      <TextField
        id={`standard-by-${label}`}
        label={label}
        defaultValue=""
        fullWidth
        multiline
        onChange={this.handleChange(component)}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderValueList = (component, label, checkOip = true) => {
    const { classes } = this.props;
    return (
      <TextField
        id={`standard-by-${label}`}
        label={label}
        defaultValue=""
        fullWidth
        multiline
        onChange={this.handleChangeList(component, checkOip)}
        InputLabelProps={{
          className: classes.input,
          shrink: true
        }}
      />
    );
  };

  renderDefault = () => {
    const { classes } = this.props;
    return (
      <Box
        justifyContent="center"
        flexDirection={"column"}
        display="flex"
        flexGrow={1}
        width={1}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          width={1}
        >
          <Box p={1} width={{ xs: 1, sm: 1 / 3 }}>
            {this.renderValue("sampleType", "Sample type")}
          </Box>
          <Box p={1} width={{ xs: 1, sm: 1 / 3 }}>
            {this.renderValue("environmentType", "Environment")}
          </Box>
          <Box p={1} width={{ xs: 1, sm: 1 / 3 }}>
            {this.renderValue("location", "Location")}
          </Box>
        </Box>
        <Box display="flex" flexDirection={"column"} width={1}>
          <Box display="flex" width={1} flexGrow={1} flexDirection="row">
            <Box p={1} width={1 / 4} flexGrow={1} flexDirection="column">
              {this.state.byListName.map((d, i) => (
                <Box key={i}>
                  <Typography variant="caption">{d}</Typography>
                </Box>
              ))}
            </Box>
            <Box p={1} width={3 / 4} flexGrow={1}>
              {this.renderValueList("byList", "Prepared by")}
            </Box>
          </Box>
          <Box display="flex" width={1} flexGrow={1} flexDirection="row">
            <Box p={1} width={1 / 4} flexGrow={1} flexDirection="column">
              {this.state.preparedFromListName.map((d, i) => (
                <Box key={i}>
                  <Typography variant="caption">{d}</Typography>
                </Box>
              ))}
            </Box>
            <Box p={1} width={3 / 4} flexGrow={1}>
              {this.renderValueList("preparedFromList", "Prepared from")}
            </Box>
          </Box>
          <Box display="flex" width={1} flexGrow={1} flexDirection="row">
            <Box p={1} width={1 / 4} flexGrow={1} flexDirection="column">
              {this.state.protocolListName.map((d, i) => (
                <Box key={i}>
                  <Typography variant="caption">{d}</Typography>
                </Box>
              ))}
            </Box>
            <Box p={1} width={3 / 4} flexGrow={1}>
              {this.renderValueList("protocolList", "Protocols")}
            </Box>
          </Box>
          <Box display="flex" width={1} flexGrow={1} flexDirection="row">
            <Box p={1} width={1 / 4} flexGrow={1} flexDirection="column">
              {this.state.organizationListName.map((d, i) => (
                <Box key={i}>
                  <Typography variant="caption">{d}</Typography>
                </Box>
              ))}
            </Box>
            <Box p={1} width={3 / 4} flexGrow={1}>
              {this.renderValueList("organizationList", "Organizations")}
            </Box>
          </Box>
          <Box display="flex" width={1} flexGrow={1} flexDirection="row">
            <Box p={1} width={3 / 4} flexGrow={1}>
              {this.renderValueList("legacyIdList", "Legacy Ids", false)}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  render() {
    const { component } = this.props;
    switch (component) {
      case "sampleType":
        return this.renderValue(component);
      case "location":
        return this.renderValue(component);
      case "environmentType":
        return this.renderValue(component);
      case "byList":
        return this.renderValueList(component);
      default:
        return this.renderDefault();
    }
  }
}

Sample.propTypes = {
  classes: PropTypes.object.isRequired,
  onChangePayload: PropTypes.func,
  component: PropTypes.string,
  label: PropTypes.string
};

export default withStyles(styles)(Sample);
