import { config } from "../ddx.config";
import { Logger } from "loglevel-colored-prefix";
import { recordProtoBuilder } from "oip-protobufjs";

class Publisher {
  constructor(wif, floNetwork, loglevel = "DEBUG") {
    this.wif = wif;
    this.floNetwork = floNetwork;
    this.base = config.oip.daemonApiUrl;
    this.log = new Logger(loglevel);
  }

  person(data) {
    const log = this.log.getLogger("Publisher::person");
    const personTemplateName = "tmpl_B6E9AF9B";
    const personTemplateDescriptor = config.publishing.templates.find(
      t => t.name === personTemplateName
    ).descriptor;
    const basicName = "tmpl_20AD45E7";
    const basicDescriptor = config.publishing.templates.find(
      t => t.name === basicName
    ).descriptor;
    log.info(data.basic);
    log.info(data.person);
    const detailsData = [
      { descriptor: basicDescriptor, payload: data.basic, name: basicName },
      {
        descriptor: personTemplateDescriptor,
        payload: data.person,
        name: personTemplateName
      }
    ];
    log.info(detailsData)
    const record = recordProtoBuilder({
      detailsData,
      wif: this.wif,
      network: this.floNetwork
    });

    const message = `p64:${record.signedMessage64}`;
    log.info(message);
    return message;
  }

  personOld(data) {
    const log = this.log.getLogger("Publisher::person");
    const personTemplateName = "tmpl_B6E9AF9B";
    const personTemplateDescriptor = config.publishing.templates.find(
      t => t.name === personTemplateName
    ).descriptor;
    log.debug(personTemplateDescriptor)
    const basicName = "tmpl_20AD45E7";
    const basicDescriptor = config.publishing.templates.find(
      t => t.name === basicName
    ).descriptor;
    log.debug(data.date);
    const date = new Date(data.date).getTime() / 1000;
    log.debug(date);
    const payload = {
      name: data.name,
      surname: data.surname,
      avatar: data.avatarOipRef,
      description: data.description,
      placeOfBirth: data.placeOfBirth,
      date
    };
    log.debug(payload)
    const detailsData = [
      { descriptor: basicDescriptor, payload, name: basicName },
      {
        descriptor: personTemplateDescriptor,
        payload,
        name: personTemplateName
      }
    ];

    const record = recordProtoBuilder({
      detailsData,
      wif: this.wif,
      network: this.floNetwork
    });

    const message = `p64:${record.signedMessage64}`;
    log.info(message);
    return message;
  }

  image(data) {
    const log = this.log.getLogger("Publisher::avatar");
    log.info(data)
    const imageTemplateName = "tmpl_1AC73C98";
    const imageTemplateDescriptor = config.publishing.templates.find(
      t => t.name === imageTemplateName
    ).descriptor;
    const basicName = "tmpl_20AD45E7";
    const basicDescriptor = config.publishing.templates.find(
      t => t.name === basicName
    ).descriptor;

    const payload = {
      name: `Avatar`,
      description: `Avatar published using ${config.title}`,
      thumbnailAddress: data.thumb,
      imageAddress: data.image
      // network: data.imgNetwork
    };
    log.info(payload);
    const detailsData = [
      { descriptor: basicDescriptor, payload, name: basicName },
      {
        descriptor: imageTemplateDescriptor,
        payload,
        name: imageTemplateName
      }
    ];
    log.info(detailsData);
    const record = recordProtoBuilder({
      detailsData,
      wif: this.wif,
      network: this.floNetwork
    });

    const message = `p64:${record.signedMessage64}`;
    log.info(message);
    return message;
  }

  sample(data) {
    const log = this.log.getLogger("Publisher::sample");
    const sampleTemplateName = "tmpl_636E68FA";
    const personTemplateDescriptor = config.publishing.templates.find(
      t => t.name === sampleTemplateName
    ).descriptor;
    const basicName = "tmpl_20AD45E7";
    const basicDescriptor = config.publishing.templates.find(
      t => t.name === basicName
    ).descriptor;
    log.info(data.basic);
    log.info(data.sample);
    const detailsData = [
      { descriptor: basicDescriptor, payload: data.basic, name: basicName },
      {
        descriptor: personTemplateDescriptor,
        payload: data.sample,
        name: sampleTemplateName
      }
    ];
    log.info(detailsData)
    const record = recordProtoBuilder({
      detailsData,
      wif: this.wif,
      network: this.floNetwork
    });

    const message = `p64:${record.signedMessage64}`;
    log.info(message);
    return message;
  }
}

export { Publisher };
