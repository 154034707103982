import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import recordPageStyle from "assets/jss/material-kit-react/views/recordPage.jsx";
import Grid from "@material-ui/core/Grid";

import { OipApi } from "oip/OipApi";
import { config } from "ddx.config.js";

const api = new OipApi(config.daemonApiUrl);
const dashboardRoutes = [];

class RecordPage extends React.Component {
  constructor(data) {
    super();
    this.oipref = data.match.params.id;
    this.state = {
      name: "",
      description: "",
      imgRef: require("assets/img/ddx-placeHolder.png"),
      placeOfBirth: ""
    };
  }

  componentDidMount() {
    api.getRecord(this.oipref).then(data => {
      const recordInfo = data.results[0].record.details;
      console.log(recordInfo);
      if (recordInfo) {
        const avatarId =
          recordInfo[config.cardInfo.avatarRecord.tmpl][
            config.cardInfo.avatarRecord.name
          ];
        const callAvatar = api.getRecord(avatarId);

        let name =
          recordInfo[config.cardInfo.name.tmpl][config.cardInfo.name.name];

        if (recordInfo[config.cardInfo.surname.tmpl]) {
          name +=
            " " +
            recordInfo[config.cardInfo.surname.tmpl][
              config.cardInfo.surname.name
            ];
        }

        const placeOfBirth =
          recordInfo[config.cardInfo.placeOfBirth.tmpl][
            config.cardInfo.placeOfBirth.name
          ];

        const description =
          recordInfo[config.cardInfo.description.tmpl][
            config.cardInfo.description.name
          ];

        this.setState({
          name,
          description,
          placeOfBirth
        });

        callAvatar.then(avatar => {
          if (avatar.results) {
            const address =
              avatar.results[0].record.details[config.imageHandler.main.tmpl][
                config.imageHandler.main.name
              ];
            this.setState({
              imgRef: `${config.ipfs.apiUrl}${address}`
            });
          }
        });
      }
    });
  }

  render() {
    console.log(this.oipref);
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand={config.title}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax className={classes.small}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title}>{this.state.name}</h1>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} className={classes.container}>
                <img
                  src={this.state.imgRef}
                  alt="avatar"
                  style={{ maxWidth: "250px" }}
                ></img>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <h3
                  className={classes.title}
                  style={{
                    marginTop: "0px",
                    marginBottom: "-10px"
                  }}
                >
                  {this.state.name}
                </h3>
                <Typography
                  style={{
                    fontSize: "8px",
                    overflowWrap: "break-word",
                    fontStyle: "italic"
                  }}
                  className={classes.root}
                >
                  <Link
                    href={`${config.floExplorer.url}/tx/${this.oipref}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {this.oipref}
                  </Link>
                </Typography>
                <p></p>
                <label className={classes.meta}>Description: </label>
                <p>{this.state.description}</p>
                <label className={classes.meta}>Place of Birth: </label>
                <p>{this.state.placeOfBirth}</p>
              </Grid>
            </Grid>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(recordPageStyle)(RecordPage);
