import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import loginPageStyle from "assets/jss/material-kit-react/views/loginPage.jsx";

import SectionForm from "./Sections/SectionForm.people";

import { Grid } from "@material-ui/core";


import { config } from "ddx.config.js";

class AddRecordsPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div>
        <Header
          color="transparent"
          brand={config.title}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />

        <Parallax
          className={classes.small}
          filter
          small
          image={require(`assets/img/landingPageImage.png`)}
        >
          <div className={classes.container}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <h1 className={classes.title}>Add records</h1>
                <h4 className={classes.subtitle}>
                  <ul>
                    <li>Fill the form</li>
                    <li>Select a FLO address</li>
                    <li>Submit</li>
                  </ul>
                </h4>
              </Grid>
            </Grid>
          </div>
        </Parallax>
        <div className={classes.container}>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Card className={classes[this.state.cardAnimaton]}>
                <CardHeader color="dd" className={classes.cardHeader}>
                  <h3>Add Record</h3>
                </CardHeader>
                <SectionForm></SectionForm>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(loginPageStyle)(AddRecordsPage);
